import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from '../components/Shared'
import ContactPage, { helmetProps } from '../components/Contact'

export const query = graphql`
  query {
    phoneIcon: file(
      name: { eq: "phone-icon" }
      relativeDirectory: { eq: "icons/contact-page" }
    ) {
      childImageSharp {
        fixed(width: 111) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    addressIcon: file(
      name: { eq: "address-icon" }
      relativeDirectory: { eq: "icons/contact-page" }
    ) {
      childImageSharp {
        fixed(width: 111) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

export default React.memo(({ data }) => (
  <>
    <Helmet {...helmetProps} />
    <ContactPage addressIcon={data.addressIcon} phoneIcon={data.phoneIcon} />
  </>
))
