import React from 'react'
import { Link } from 'gatsby'
import { SocialBar } from '../../Shared'

const ContactInfo = () => (
  <div className="contact-info">
    <div className="contact-content">
      {/* <div className="contact-row general">
        <p className="header">General Inquiries</p>
        <p className="underline">
          <a href="mailto:info@irisvr.com">info@irisvr.com</a>
        </p>
      </div>
      <div className="contact-row general">
        <p className="header">Sales & Pricing Inquiries</p>
        <p className="underline">
          <a href="mailto:sales@irisvr.com">sales@irisvr.com</a>
        </p>
      </div> */}
      <div className="contact-row support">
        <p className="header">Tech Support</p>
        <p className="underline">
          <a
            href="mailto:support@irisvr.com?subject=Technicial%20Support%20Request"
            target="_blank"
            rel="noopener noreferrer"
          >
            Request help
          </a>
        </p>
      </div>
      <div className="contact-row newsletter">
        <p className="header">Get the latest in VR</p>
        <SocialBar iconStyle={{ height: '16px' }} />
      </div>
    </div>
  </div>
)

export default ContactInfo
