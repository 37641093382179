import { getWindowLocationOrigin } from '../../utils/window'
import { generateMetaTags } from '../../utils/helmet'

const title = 'Contact Us | IrisVR'
const description =
  "Thanks for your interest in IrisVR! Here's how to get in contact with our team. We're happy to answer questions about 3D models, Prospect, and more."
const url = `${getWindowLocationOrigin()}/contact`
const image =
  'https://s3.amazonaws.com/iris-opengraph-images/ImgTag_Contact.jpg'
const imageWidth = 1200
const imageHeight = 630

export default generateMetaTags({
  title,
  description,
  image,
  url,
  imageWidth,
  imageHeight,
})
