import React, { useEffect } from 'react'
import { Layout } from '../Shared'
import helmetProps from './helmetProps'
import ContactInfo from './ContactInfo'
import './ContactPage.global.scss'

const Form = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '6115928',
          formId: 'c3e28323-cb89-400a-ae77-b99b36b3f7be',
          target: '#contact-form',
        })
      }
    })
  }, [])
  return <div id="contact-form" />
}

const ContactPage = ({ addressIcon, phoneIcon, width }) => (
  <div id="contact-page" className="full-height">
    <div className="splash-header" />
    <div className="splash-body">
      <div className="container">
        <Form />
        <ContactInfo />
      </div>
    </div>
  </div>
)

export default props => (
  <Layout>
    <ContactPage {...props} />
  </Layout>
)

export { helmetProps }
